$total: 300; // total particles
$orb-size: 80px;
$time: 7s; 
$particle-size: 3px;
$base-hue: 0; // change for diff colors (180 is nice)

.scss_wrap {
    margin-top: $orb-size + 50px;
    position: relative;
    top: 50%;
    left: 50%;
    width: 0; 
    height: 0; 
    transform-style: preserve-3d;
    perspective: 1000px;
    animation: rotate $time infinite linear; // rotate orb
}

@keyframes rotate {
  100% {
    transform: rotateY(360deg) rotateX(360deg);
  }
}

.scss_particle {
  position: absolute;
  width: $particle-size;
  height: $particle-size;
  border-radius: 50%;
  opacity: 0; 
}

.scss_content {
    text-align: center;
    padding-bottom: 50px;
    padding-top: $orb-size + 50px;
}

@for $i from 1 through $total {
  $z: (random(360) * 1deg); // random angle to rotateZ
  $y: (random(360) * 1deg); // random to rotateX
  $hue: ((40/$total * $i) + $base-hue); // set hue
  
  .scss_particle:nth-child(#{$i}){ // grab the nth particle
    animation: orbit#{$i} $time infinite;
    animation-delay: ($i * .01s); 
    background-color: hsla($hue, 100%, 50%, 1);
  }

  @keyframes orbit#{$i}{ 
    20% {
      opacity: 1; // fade in
    }
    30% {
      transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // form orb
    }
    80% {
      transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // hold orb state 30-80
      opacity: 1; // hold opacity 20-80
    }
    100% {
       transform: rotateZ(-$z) rotateY($y) translateX( ($orb-size * 3) ) rotateZ($z); // translateX * 3
    }
  }
}