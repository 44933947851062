.svg_ {
    overflow: visible;
}
.svg_ .shadow {
    filter: url(#blur);
    fill: #2a3e4d;
    opacity: 0.4;
}
.svg_ .core circle {
    fill: url(#gradient);
}
.svg_ .core circle:first-child {
    filter: url(#blur);
    opacity: 0.5;
}
.svg_ .ring ellipse {
    stroke-width: 2px;
    fill: none;
    transform-origin: center;
    stroke-dasharray: 109 6;
    stroke-dashoffset: 26;
}

.svg_ .ring ellipse:nth-child(3) {
    stroke-dasharray: 0 230;
    animation-name: orbit;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    stroke-width: 7px;
    stroke-linecap: round;
}
.svg_ .ring ellipse:nth-child(4) {
    stroke-dasharray: 0 230;
    animation-name: orbit;
    animation-duration: 3s;
    animation-delay: -1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    stroke-width: 7px;
    stroke-linecap: round;
}
.svg_ .ring:nth-child(2) ellipse {
    animation-duration: 6s;
    animation-direction: reverse;
}
.svg_ .ring:nth-child(3) ellipse:nth-child(3) {
    animation-delay: -1s;
}

.color1 {
    stop-color: #0faad6;
    stop-opacity: 1;
}

.color2 {
    stop-color: #176ebf;
    stop-opacity: 1;
}

.white1 {
    stop-color: #ffffff;
    stop-opacity: 1;
}

.white2 {
    stop-color: #969696;
    stop-opacity: 1;
}

@keyframes orbit {
    0% {
        stroke-dashoffset: 0;
    }
    50% {
        stroke-width: 4px;
    }
    100% {
        stroke-dashoffset: 230;
    }
}
